<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">Keep Your Profile and Data</v-card-title>
      <div v-if="showOk">
        <v-card-text>   
          <v-alert type="success">
            <p>Your profile will not be removed. You can always request removal at a later time.</p>
          </v-alert>

        </v-card-text>
      </div>
      <div v-else-if="profile && profile.delete_initiated">
        <v-card-text>   
          <v-alert type="info">
            Your profile and all your data is scheduled to be deleted within 7 days from <strong>{{profile.delete_initiated | localDate }}</strong>.          </v-alert>
          <p>
            On your request, we can cancel the removal request.
          </p>
          <p>
            You can always request your profile and data to be removed at a later time through the Edit Profile option.
          </p>
        </v-card-text>
        
        <v-card-text>   
          <v-btn block :disabled="!isValid" color="primary" :loading="$store.getters.isLoading" @click="submit">Keep Your Profile</v-btn>
        </v-card-text>   

      </div>    
      <div v-else-if="profile && profile.life_cycle_event">
        <v-card-text>   
          <v-alert type="info">
            Your profile is managed by <strong>{{profile.life_cycle_event.name}}</strong>.
          </v-alert>
          <p>
            On your request, we can disconnect your profile from the event so it won't get cleaned-up automatically.
          </p>
          <p>
            You can always request your profile and data to be removed at a later time through the Edit Profile option.
          </p>
        </v-card-text>
        
        <v-card-text>   
          <v-btn block :disabled="!isValid" color="primary" :loading="$store.getters.isLoading" @click="submit">Keep Your Profile</v-btn>
        </v-card-text>   

      </div>       
      <div v-else-if="profile">
        <v-card-text>   
          <p>
            Your profile will not be cleaned-up automatically. No action is required.
          </p>
          <p>
            You can always request your profile and data to be removed at a later time through the Edit Profile option.
          </p>
        </v-card-text>
      </div>

    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Keep', disabled: true },
      ],
      profile: null,
      showOk: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async submit() {
      let response = await profileService.keepProfile();
      if (response.data.status == 'OK') {
        this.showOk = true;
      }
      else {
        this.$helpers.toastResponse(this, response.data);
      }
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
      }
    },
  },
  computed: {
    isValid() {
      return this.profile && this.profile.email;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

